<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <SwitzLogo /> -->

        <img class="fp_logo" :src="logo" alt="" style="object-fit: contain" />

        <h2 class="brand-text text-primary ml-1">
          <!-- Switz -->
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your
            password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="forgot-password-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="forgot-password-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block>
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>

      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SwitzLogo from "@/theme-variables/assets/Logo.png";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      logo: SwitzLogo,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            const response = await AuthServices.sendPasswordResetLink({
              user_email: this.userEmail,
            });
            if (response.data.status) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message || "Reset Link Sent",
                  icon: "SendIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/");
            } else {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title:
                    response.data.message || "Cannot Send Reset Password Link",
                  icon: "X",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in sendPasswordResetLink ", error);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Failed",
                icon: "X",
                variant: "failure",
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.fp_logo {
  height: 50px;
  width: 50px;
}

@import "@core/scss/vue/pages/page-auth.scss";
</style>
